import { useCopyToClipboard } from "@/hooks";
import { CodeCTAs } from "./code-ctas";
import { InteractiveCodeSnippet } from "./interactive-code-snippet";
import { makeHTTPRunSnippet } from "@/code-snippets";
import { getModelDisplayInput } from "@/util";
import { isOfficialModel, isStreamingModel } from "@/data";
import type { PlaygroundModel } from "@/types";

export function CurlCodeForm({
  name,
  model,
  prompt,
}: {
  // name of the field
  name: string;
  model: PlaygroundModel;
  prompt: string;
}) {
  const [copiedValue, copy] = useCopyToClipboard();
  const modelFullName = `${model.owner}/${model.name}`;

  const isOfficial = isOfficialModel(modelFullName);
  const isStreaming = isStreamingModel(modelFullName);

  const code = makeHTTPRunSnippet({
    model,
    input: getModelDisplayInput({ model, name, prompt }),
    isOfficial,
    isStreaming,
  });

  const handleCopy = () => {
    copy(code);
  };

  return (
    <div className="group">
      <CodeCTAs onCopy={handleCopy} copiedValue={copiedValue} />
      <InteractiveCodeSnippet language="bash" code={code} />
    </div>
  );
}
