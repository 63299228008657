import { TERMINAL_PREDICTION_STATUSES } from "@/constants";
import {
  useCancelPendingPredictions,
  useCreatePrediction,
  useGetPrediction,
} from "@/hooks";
import {
  HeroStateContext,
  createHeroStore,
  useActiveModel,
  useHeroContext,
} from "@/store";
import { getInputPropertyName } from "@/util";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import type { Model } from "replicate";
import { InputForm } from "./playground/input-form";
import { LanguageTabs } from "./playground/language-tabs";
import { ModelList } from "./playground/model-list";
import { PlaygroundFormOutput } from "./playground/playground-form-output";
import type { PlaygroundModel } from "@/types";

export function HeroPlayground({
  useCases,
}: {
  useCases: {
    label: string;
    models: PlaygroundModel[];
  }[];
}) {
  const store = useRef(createHeroStore()).current;
  const models = useCases.flatMap((u) => {
    return u.models.map((m) => {
      return {
        ...m,
        useCase: u.label,
      };
    });
  });

  const defaultPrompt =
    // @ts-ignore
    useCases[0].models[0].default_example?.input?.prompt ?? "";

  store.setState({
    models,
    prompt: defaultPrompt,
  });

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <HeroStateContext.Provider value={store}>
        <HeroPlaygroundInner />
      </HeroStateContext.Provider>
    </QueryClientProvider>
  );
}

function HeroPlaygroundInner() {
  // State that's used to determine what to display on the right side.
  const [submitState, setSubmitState] = useState<"pre" | "post">("pre");
  const interacted = useHeroContext((s) => s.interacted);
  const setInteracted = useHeroContext((s) => s.setInteracted);

  const activeModel = useActiveModel();
  const defaultExample = activeModel.default_example;

  const createPredictionMutation = useCreatePrediction();

  const isDefaultExamplePrediction =
    defaultExample.id === createPredictionMutation.data?.id;

  const handleSubmit = async ({
    model,
    prompt,
  }: {
    model: Model;
    prompt: string;
  }) => {
    const inputName = getInputPropertyName(model.owner, model.name);
    setSubmitState("post");
    createPredictionMutation.mutate({
      model,
      prompt,
      name: inputName,
      defaultExample,
    });
  };

  const cancelPendingPredictions = useCancelPendingPredictions();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    createPredictionMutation.reset();
    setSubmitState("pre");

    if (isDefaultExamplePrediction) return;
    cancelPendingPredictions.mutate();
  }, [activeModel]);

  const predictionQuery = useGetPrediction({
    id: createPredictionMutation.data?.id,
    defaultExample,
  });

  useEffect(() => {
    function handlePageHide() {
      if (isDefaultExamplePrediction) return;
      cancelPendingPredictions.mutate();
    }
    window.addEventListener("pagehide", handlePageHide);
    return () => void window.removeEventListener("pagehide", handlePageHide);
  }, [cancelPendingPredictions, isDefaultExamplePrediction]);

  const predictionIsRunning = Boolean(
    predictionQuery.data &&
      !TERMINAL_PREDICTION_STATUSES.includes(predictionQuery.data.status),
  );

  return (
    <div className="relative">
      <div className="bg-white">
        <ModelList />
        <div
          onPointerMove={() => {
            if (!interacted) {
              setInteracted(true);
            }
          }}
          className="lg:p-6 bg-white"
        >
          <div className="bg-white">
            <div className="border-l-0 border-r-0 border-t border-b lg:border border-black bg-white lg:h-[25rem] flex flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0 divide-black">
              <div className="w-full lg:w-1/2">
                <div className="flex flex-col h-full">
                  <InputForm
                    onSubmit={handleSubmit}
                    loading={
                      createPredictionMutation.status === "pending" ||
                      predictionIsRunning
                    }
                  />
                  <LanguageTabs />
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="overflow-hidden relative h-full flex flex-col max-h-80 lg:max-h-full">
                  <div className="flex-shrink-0 lg:flex-1 overflow-hidden flex flex-col h-80 lg:h-full">
                    <PlaygroundFormOutput
                      mutation={createPredictionMutation}
                      query={predictionQuery}
                      submitState={submitState}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
