import type { APIPrediction } from "@/types";
import type { UseQueryResult } from "@tanstack/react-query";
import { PredictionLoadingState } from "./prediction-loading-state";
import { PredictionErrorState } from "./prediction-error-state";
import { PredictionOutput } from "./prediction-output";
import { match, P } from "ts-pattern";
import { useActiveModel } from "@/store";

export function PredictionDetail({
  query,
}: {
  query: UseQueryResult<APIPrediction, Error>;
}) {
  const activeModel = useActiveModel();
  const defaultExample = activeModel.default_example;
  const isShowingDefaultExample = query.data?.id === defaultExample.id;

  if (query.isPending) {
    return <PredictionLoadingState />;
  }

  if (query.isError) {
    return <PredictionErrorState error={query.error} />;
  }

  if (query.isSuccess) {
    return match({ status: query.data.status, output: query.data.output })
      .with({ status: "starting" }, () => {
        return <PredictionLoadingState logs={query.data?.logs} />;
      })
      .with(
        { status: "succeeded", output: P.not(P.nullish) },
        { status: "processing", output: P.not(P.nullish) },
        ({ output }) => {
          return (
            <PredictionOutput
              id={query.data.id}
              input={query.data.input}
              output={output}
            />
          );
        },
      )
      .with(
        {
          status: "succeeded",
          output: P.nullish,
        },
        () => {
          return (
            <div className="h-full flex-col flex items-center justify-center relative text-black bg-white">
              <div className="text-center z-10">
                <p className="text-sm">No output</p>
              </div>
            </div>
          );
        },
      )
      .with(
        {
          status: "processing",
          output: P.nullish,
        },
        () => {
          return <PredictionLoadingState logs={query.data.logs} />;
        },
      )
      .otherwise(() => {
        return <PredictionErrorState error={new Error("Prediction failed")} />;
      });
  }

  return null;
}
