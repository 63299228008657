import { useActiveModel } from "@/store";
import { getInputPropertyName, getOutputPropertyName, getSchema } from "@/util";
import { match } from "ts-pattern";
import { URIValue } from "./uri-value";
import { BeforeAfterOutput } from "./before-after-output";
import { overrides } from "@/data";
import { FallbackValue } from "./fallback-value";

const beforeAfterModels = ["tencentarc/gfpgan", "nightmareai/real-esrgan"];

export function PredictionOutput({
  output,
  input,
  id,
}: {
  output?: unknown;
  input?: Record<string, unknown> | object;
  id?: string;
}) {
  const activeModel = useActiveModel();
  const { output: schema } = getSchema(activeModel);
  const fullModelName = `${activeModel.owner}/${activeModel.name}`;

  // Major hack. For suno-ai/bark, the schema for the default_example is different
  // than the schema for the latest version at the time of writing.
  // This means we can't use the schema from the latest version to determine how to display the output.
  // As a workaround, I've hard-coded the schema for the default_example in our trusty "overrides" object.
  // I've also added a function to the overrides object that transforms the output to match the schema.
  // This is helpful in the case of bark, where the default_example output is a string,
  // but the latest version output is an object.
  const transformedOutput = overrides[fullModelName]?.transformOutput
    ? overrides[fullModelName]?.transformOutput?.(output)
    : output;

  if (
    typeof output === "string" &&
    beforeAfterModels.includes(fullModelName) &&
    activeModel.default_example
  ) {
    const inputFieldName = getInputPropertyName(
      activeModel.owner,
      activeModel.name,
    );

    const defaultInput = activeModel.default_example.input as Record<
      string,
      unknown
    >;
    const beforeInput =
      // @ts-ignore
      (input ? input[inputFieldName] : defaultInput[inputFieldName]) as string;
    return (
      <BeforeAfterOutput inputURL={beforeInput} outputURL={transformedOutput} />
    );
  }

  return match(schema)
    .with({ type: "array", items: { type: "string", format: "uri" } }, () => {
      // let's naively take the first item in the array.
      const firstOutput = Array.isArray(transformedOutput)
        ? transformedOutput[0]
        : transformedOutput;

      return <URIValue key={firstOutput} uri={firstOutput} />;
    })
    .with(
      {
        type: "array",
        items: {
          type: "string",
        },
        "x-cog-array-type": "iterator",
        "x-cog-array-display": "concatenate",
      },
      () => {
        if (!Array.isArray(transformedOutput)) {
          return (
            <FallbackValue
              id={id}
              model={fullModelName}
              schema={schema}
              value={transformedOutput}
            />
          );
        }

        return (
          <pre className="bg-white text-black h-full text-sm overflow-auto p-4 whitespace-pre-wrap">
            {(transformedOutput as string[]).join("")}
          </pre>
        );
      },
    )
    .with(
      {
        type: "string",
        format: "uri",
      },
      () => {
        return <URIValue uri={transformedOutput as string} />;
      },
    )
    .with(
      {
        type: "object",
      },
      () => {
        const outputPropertyName = getOutputPropertyName(
          activeModel.owner,
          activeModel.name,
        );
        if (!outputPropertyName) return null;
        return (
          <URIValue
            uri={
              (transformedOutput as Record<string, string>)[outputPropertyName]
            }
          />
        );
      },
    )
    .otherwise(() => (
      <FallbackValue model={fullModelName} schema={schema} value={output} />
    ));
}
