import { Lowlight } from "../lowlight";

export function InteractiveCodeSnippet({
  code,
  language,
  markers = [],
}: {
  code: string;
  language: string;
  markers?: { line: number; className: string }[];
}) {
  return (
    <div className="github-light group playground-code p-4">
      <pre>
        <Lowlight markers={markers} inline language={language} value={code} />
      </pre>
    </div>
  );
}
