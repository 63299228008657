import { createStore, useStore } from "zustand";
import { createContext, useContext } from "react";
import type { PlaygroundModelWithUseCase } from "./types";
import { getInputPropertyName } from "./util";

interface HeroState {
  // used to determine if the user has interacted with the carousel
  interacted: boolean;
  setInteracted: (interactedWith: boolean) => void;
  models: PlaygroundModelWithUseCase[];
  modelIndex: number;
  setModelIndex: (index: number) => void;
  language: string;
  setLanguage: (language: string) => void;
  prompt: string;
  setPrompt: (prompt: string) => void;
}

export const createHeroStore = (initProps?: Partial<HeroState>) => {
  return createStore<HeroState>()((set, get) => ({
    interacted: false,
    setInteracted: (interactedWith: boolean) => {
      set({ interacted: interactedWith });
    },
    models: [],
    modelIndex: 0,
    setModelIndex: (index: number) => {
      const currentState = get();
      const newModel = currentState.models[index];

      const inputPropertyName = getInputPropertyName(
        newModel.owner,
        newModel.name,
      );

      // @ts-ignore
      const maybePrompt = newModel?.default_example?.input?.[inputPropertyName];

      return set({
        modelIndex: index,
        prompt: maybePrompt ?? currentState.prompt,
      });
    },
    language: "python",
    setLanguage: (language: string) => set({ language: language }),
    prompt: "",
    setPrompt: (prompt: string) => set({ prompt: prompt }),
    ...initProps,
  }));
};

type HeroStateStore = ReturnType<typeof createHeroStore>;

export const HeroStateContext = createContext<HeroStateStore | null>(null);

export function useHeroContext<T>(selector: (state: HeroState) => T): T {
  const store = useContext(HeroStateContext);
  if (!store) throw new Error("Missing HeroState.Provider in the tree");
  return useStore(store, selector);
}

export function useActiveModel() {
  return useHeroContext((state) => state.models[state.modelIndex]);
}
