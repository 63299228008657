import * as Sentry from "@sentry/astro";
import { useEffect } from "react";

export function FallbackValue({
  model,
  schema,
  value,
  id,
}: {
  model: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  schema: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value: any;
  id?: string;
}) {
  useEffect(() => {
    Sentry.captureException(new Error("API Playground: Unhandled value"), {
      extra: {
        model,
        schema: JSON.stringify(schema),
        value: JSON.stringify(value),
        id,
      },
    });
  }, [model, schema, value, id]);

  const renderedValue =
    typeof value !== "string" ? JSON.stringify(value, null, 2) : value;

  // TODO: Once we're confident this is only happening when the schema and
  // output are mismatched, show something to the user to help them debug what's
  // wrong.
  return (
    <div className="whitespace-pre p-4 overflow-auto" translate="no">
      {renderedValue}
    </div>
  );
}
