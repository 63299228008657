import { type Model } from "replicate";
import { useActiveModel, useHeroContext } from "@/store";
import { useRef, useState } from "react";
import { useWindupString } from "windups";
import { useReducedMotion } from "framer-motion";

export function InputForm({
  onSubmit,
  loading,
}: {
  onSubmit: ({ model, prompt }: { model: Model; prompt: string }) => void;
  loading: boolean;
}) {
  const prompt = useHeroContext((s) => s.prompt);
  const setPrompt = useHeroContext((s) => s.setPrompt);
  const activeModel = useActiveModel();

  const inputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<"initial" | "typed">(
    prompt ? "initial" : "typed",
  );

  const reducedMotion = useReducedMotion();

  const [windup] = useWindupString(prompt, {
    skipped: Boolean(reducedMotion),
    onFinished: () => {
      setState("typed");
    },
  });

  return (
    <div className="relative">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({
            prompt,
            model: activeModel,
          });
        }}
        className="border-b border-black relative"
      >
        <div className="bg-white relative border-none">
          <div className="flex gap-1">
            <div className="flex-1 overflow-ellipsis truncate">
              {state === "initial" && (
                <div className="w-full flex items-center p-3 whitespace-nowrap leading-6 h-full">
                  <span className="overflow-ellipsis truncate">{windup}</span>
                </div>
              )}
              {state === "typed" && (
                <div className="relative h-full">
                  <input
                    ref={inputRef}
                    type="text"
                    name="prompt"
                    value={prompt}
                    required
                    placeholder="Type your prompt here"
                    onChange={(e) => {
                      setPrompt(e.target.value);
                    }}
                    className="w-full h-full bg-transparent text-sm lg:text-base border-0 p-3 focus:ring-0 overflow-ellipsis truncate"
                  />
                </div>
              )}
            </div>
            <div className="flex-shrink-0 py-2 pr-2">
              <button
                disabled={loading}
                className="btn btn-primary text-sm disabled:opacity-50 disabled:cursor-wait"
                type="submit"
              >
                {loading ? "Running..." : "Run model"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
