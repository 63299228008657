import * as Ariakit from "@ariakit/react";
import { Copy, Check } from "@phosphor-icons/react";

export function CodeCTAs({
  onCopy,
  copiedValue,
}: {
  disabled?: boolean;
  onCopy: () => void;
  copiedValue: string | null;
}) {
  return (
    <div className="absolute top-2 right-3 flex items-center gap-3">
      <Ariakit.TooltipProvider>
        <Ariakit.TooltipAnchor
          render={
            <button
              type="button"
              onClick={() => {
                onCopy();
              }}
              className="w-6 h-6 hover:bg-r8-gray-2 text-r8-gray-11 flex items-center justify-center"
            />
          }
        >
          {copiedValue ? <Check /> : <Copy weight="duotone" />}
        </Ariakit.TooltipAnchor>
        <Ariakit.Tooltip className="bg-black text-white px-2 py-1.5 text-xs">
          <Ariakit.TooltipArrow />
          <span>{copiedValue ? "Copied!" : "Copy code"}</span>
        </Ariakit.Tooltip>
      </Ariakit.TooltipProvider>
    </div>
  );
}
