import { Pause, Play } from "@phosphor-icons/react";
import { useCallback, useEffect, useRef, useState } from "react";
import WaveSurfer, { type WaveSurferOptions } from "wavesurfer.js";

const useWavesurfer = (
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  url: string,
  options?: Omit<WaveSurferOptions, "container">,
) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!containerRef.current) return;

    const ws = WaveSurfer.create({
      ...options,
      barWidth: 3,
      barGap: 1,
      barRadius: 2,
      dragToSeek: true,
      url,
      height: "auto",
      container: containerRef.current,
      waveColor: "#CCC",
      progressColor: "#000",
    });

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [options, containerRef]);

  return wavesurfer;
};

export const AudioPlayer = ({ url }: { url: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const wavesurfer = useWavesurfer(containerRef, url);

  // On play button click
  const onPlayClick = useCallback(() => {
    if (!wavesurfer) return;
    wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
  }, [wavesurfer]);

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!wavesurfer) return;

    setCurrentTime(0);
    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("timeupdate", (currentTime) => setCurrentTime(currentTime)),
      wavesurfer.on("ready", () => {
        setDuration(wavesurfer.getDuration());
      }),
    ];

    return () => {
      // biome-ignore lint/complexity/noForEach: <explanation>
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  return (
    <div>
      <div className="h-24 border border-black">
        <div
          className="flex-1 h-full w-full audio-player-wrap"
          ref={containerRef}
          style={{ minHeight: "100%" }}
        />
      </div>
      <div className="mt-1 flex items-center justify-between text-black">
        <div className="text-xs">{currentTime.toFixed(2)}</div>
        <div className="text-xs">{duration.toFixed(2)}</div>
      </div>
      <div className="mt-2 flex items-center justify-center">
        <button
          className="w-12 h-12 rounded-full bg-black border-black border-2 bg-black/10 text-black flex items-center justify-center "
          onClick={onPlayClick}
          type="button"
        >
          {isPlaying ? <Pause weight="fill" /> : <Play weight="fill" />}
        </button>
      </div>
    </div>
  );
};
