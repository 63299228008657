import mime from "mime";
import { match, P } from "ts-pattern";
import { AudioPlayer } from "./audio-player";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import { useEffect, useState } from "react";

function LazyImage({ src }: { src: string }) {
  const [loaded, setLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
      setImageSrc(src);
    };

    // ignoring errors for now
    img.onerror = () => {};
  }, [src]);

  return (
    <div className="flex items-center justify-center h-full bg-white">
      <AnimatePresence>
        {loaded && (
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="h-full mx-auto"
            alt="Output"
            src={imageSrc}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export function URIValue({ uri }: { uri: string }) {
  const mimeType = mime.getType(uri);
  const reducedMotion = useReducedMotion();

  return match(mimeType)
    .with(
      P.when((mimeType) => mimeType?.startsWith("image")),
      () => {
        return <LazyImage src={uri} />;
      },
    )
    .with(
      P.when((mimeType) => mimeType?.startsWith("video")),
      () => {
        return (
          // biome-ignore lint/a11y/useMediaCaption: <explanation>
          <video
            className="h-full mx-auto"
            controls
            playsInline
            src={uri}
            autoPlay={!reducedMotion}
            loop
          />
        );
      },
    )
    .with(
      P.when((mimeType) => mimeType?.startsWith("audio")),
      () => {
        return (
          <div className="flex h-full items-center px-8 bg-white">
            <div className="lg:max-w-xl w-full mx-auto">
              <AudioPlayer url={uri} />
            </div>
          </div>
        );
      },
    )
    .otherwise(() => {
      return <div>Unhandled mime type</div>;
    });
}
