import { listOfUseCasesAndModels } from "@/data";
import { useHeroContext } from "@/store";
import type { PlaygroundModel } from "@/types";
import { Command } from "@ariakit/react";
import { ArrowBendRightDown } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { motion, useReducedMotion } from "framer-motion";
import { InlineModelCard, StackedModelCard } from "../model-card";

import { Swiper, SwiperSlide, type SwiperClass } from "swiper/react";
import { A11y, FreeMode, Mousewheel } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import { useRafInterval, useResponsive } from "ahooks";

const Slide = ({
  model,
  onClick,
  isActive,
}: {
  model: PlaygroundModel;
  onClick: () => void;
  isActive: boolean;
}) => {
  return (
    <div className={`flex-shrink-0 relative group p-2 ${!isActive ? "" : ""}`}>
      <Command
        onClick={() => {
          onClick();
        }}
        id={`model-${model.owner}-${model.name}`}
        className={`text-left appearance-none w-full h-full relative ring-4 ${
          isActive
            ? "ring-black/40 opacity-100"
            : "hover:ring-black/10 ring-transparent opacity-50 hover:opacity-100"
        }`}
      >
        <div className="h-[480px] xs:h-[500px] sm:hidden">
          <StackedModelCard
            loading="eager"
            model={model}
            linkToReplicate={true}
          />
        </div>
        <div className="h-44 hidden sm:block">
          <InlineModelCard
            loading="eager"
            model={model}
            linkToReplicate={true}
          />
        </div>
      </Command>
    </div>
  );
};

export function ModelList() {
  const [swiperReady, setSwiperReady] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const models = useHeroContext((s) => s.models);
  const modelIndex = useHeroContext((s) => s.modelIndex);
  const setModelIndex = useHeroContext((s) => s.setModelIndex);
  const interacted = useHeroContext((s) => s.interacted);
  const setInteracted = useHeroContext((s) => s.setInteracted);
  const reducedMotion = useReducedMotion();
  const responsive = useResponsive();

  const swiperRef = useRef<SwiperClass | null>(null);

  const handleUseCaseSelect = (index: number) => {
    // We need to figure out the index of the first model in the use case
    // and then scroll to that index.
    const [firstModel] = listOfUseCasesAndModels[index].models;
    const { owner, name } = firstModel;
    const indexOfFirstModel = models.findIndex(
      (m) => m.name === name && m.owner === owner,
    );
    setModelIndex(indexOfFirstModel);
    swiperRef.current?.slideToLoop(indexOfFirstModel, reducedMotion ? 0 : 500);
  };

  useRafInterval(
    () => {
      swiperRef.current?.slideNext(reducedMotion ? 0 : 500);
    },
    interacted ? undefined : 3500,
  );

  useEffect(() => {
    if (!swiperRef.current) return;
    // There's a known issue with the "mouseWheel" module in Swiper
    // (the one that allows you to scroll with the mouse wheel)
    // which prevents us from conditionally enabling the module based on the screen size.
    //
    // When this module runs on smaller screens sizes and users scroll with their mouse wheel,
    // it results in a "double scroll" where a single swipe of the mouse wheel scrolls two slides at a time.
    // This useEffect is a hack to get around that issue.
    if (responsive.md || responsive.lg || responsive.xl) {
      // If so, enable the mouseWheel module if disabled.
      if (!swiperRef.current.mousewheel.enabled) {
        swiperRef.current.mousewheel.enable();
      }
    } else {
      // Otherwise, disable it if enabled.
      if (swiperRef.current.mousewheel.enabled) {
        swiperRef.current.mousewheel.disable();
      }
    }
  }, [responsive]);

  return (
    <>
      <div className="overscroll-contain">
        <div className="p-6">
          <div className="inline-flex items-center gap-1 mb-2 text-black">
            <p className="text-sm relative">With Replicate you can</p>
            <ArrowBendRightDown weight="fill" />
          </div>
          <div className="flex flex-wrap gap-4">
            {listOfUseCasesAndModels.map((useCase, index) => {
              // we need a variable isActive that lets us know whether the active slide – represented by ativeSlide
              // is the first slide in the use case. If it is, we want to highlight the use case.
              const isActive = useCase.models.some(
                (m) =>
                  m.name === models[activeIndex].name &&
                  m.owner === models[activeIndex].owner,
              );

              return (
                <button
                  type="button"
                  onClick={() => {
                    setInteracted(true);
                    handleUseCaseSelect(index);
                  }}
                  key={useCase.label}
                  aria-selected={isActive}
                  className={
                    "rounded-full active:translate-y-px transform ring-1 ring-black hover:bg-r8-gray-3 py-1 px-3 text-sm transition-colors bg-white aria-selected:bg-black aria-selected:text-white"
                  }
                >
                  {useCase.label}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div className="relative row mx-auto">
        <div className="bg-white pb-4 lg:pb-0">
          <motion.div
            onPointerOver={() => {
              if (!interacted) {
                setInteracted(true);
              }
            }}
            animate={{
              opacity: swiperReady ? 1 : 0,
            }}
            initial={{
              opacity: 0,
            }}
            className=""
          >
            <Swiper
              grabCursor={true}
              modules={[A11y, FreeMode, Mousewheel]}
              slidesPerView={1.25}
              slidesOffsetBefore={0}
              mousewheel={{
                forceToAxis: true,
              }}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 2,
                  slidesOffsetBefore: 16,
                  freeMode: {
                    enabled: true,
                  },
                },
                1280: {
                  slidesPerView: 2.75,
                  slidesOffsetBefore: 16,
                  freeMode: {
                    enabled: true,
                  },
                },
              }}
              onSlideChange={(ev) => {
                if (!interacted || !responsive.lg) {
                  setModelIndex(ev.realIndex);
                }

                setActiveIndex(ev.realIndex);
              }}
              onSwiper={(swiper) => {
                setSwiperReady(true);
                swiperRef.current = swiper;
              }}
            >
              {models.map((model, index) => {
                const id = `${model.owner}/${model.name}`;
                return (
                  <SwiperSlide className="" key={`model-${id}-${index}`}>
                    <Slide
                      isActive={modelIndex === index}
                      onClick={() => {
                        setInteracted(true);
                        const idx = models.findIndex(
                          (m) =>
                            m.name === model.name && m.owner === model.owner,
                        );
                        setModelIndex(idx);
                        swiperRef?.current?.slideToLoop(
                          idx,
                          reducedMotion ? 0 : 500,
                        );
                      }}
                      model={model}
                      key={id}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </motion.div>
        </div>
      </div>
    </>
  );
}
