import { useActiveModel, useHeroContext } from "@/store";
import * as Ariakit from "@ariakit/react";
import { PythonCodeForm } from "./python-code-form";
import { JSCodeForm } from "./js-code-form";
import { CurlCodeForm } from "./curl-code-form";
import { getInputPropertyName } from "@/util";

export function LanguageTabs() {
  const language = useHeroContext((state) => state.language);
  const setLanguage = useHeroContext((state) => state.setLanguage);
  const prompt = useHeroContext((state) => state.prompt);
  const activeModel = useActiveModel();

  const modelName = `${activeModel.owner}/${activeModel.name}`;
  const inputPropertyName = getInputPropertyName(
    activeModel.owner,
    activeModel.name,
  );

  return (
    <Ariakit.TabProvider
      activeId={language}
      setActiveId={(val) => {
        if (val) {
          setLanguage(val);
        }
      }}
    >
      <div className="flex flex-col overflow-hidden h-full">
        <Ariakit.TabList className="px-4 border-b border-r8-gray-8 flex gap-4">
          {["Python", "JavaScript", "cURL"].map((lang) => {
            return (
              <Ariakit.Tab
                key={lang}
                id={lang.toLowerCase()}
                className="pt-3 pb-3 px-2 text-sm border-b-2 border-transparent text-r8-gray-10 aria-selected:text-black hover:text-r8-gray-11 aria-selected:border-black top-px relative aria-selected:font-semibold focus:outline-none"
              >
                {lang}
              </Ariakit.Tab>
            );
          })}
        </Ariakit.TabList>

        <div className="relative group flex-1 h-full overflow-auto">
          <Ariakit.TabPanel
            alwaysVisible={false}
            className="flex-1 overflow-auto h-full"
          >
            <PythonCodeForm
              prompt={prompt}
              model={activeModel}
              name={inputPropertyName}
            />
          </Ariakit.TabPanel>
          <Ariakit.TabPanel
            alwaysVisible={false}
            className="flex-1 overflow-auto h-full"
          >
            <JSCodeForm
              prompt={prompt}
              model={activeModel}
              name={inputPropertyName}
            />
          </Ariakit.TabPanel>
          <Ariakit.TabPanel
            alwaysVisible={false}
            className="flex-1 overflow-auto h-full"
          >
            <CurlCodeForm
              prompt={prompt}
              model={activeModel}
              name={inputPropertyName}
            />
          </Ariakit.TabPanel>
        </div>
        <div className="px-4 pb-4 pt-4 flex-shrink-0 mt-auto">
          <a
            href={`https://replicate.com/${modelName}`}
            className="btn btn-secondary text-sm"
          >
            Run <span className="font-semibold mx-1">{modelName}</span> with an
            API
          </a>
        </div>
      </div>
    </Ariakit.TabProvider>
  );
}
