import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

function getImageAspectRatio(url: string) {
  return new Promise<number>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img.width / img.height);
    };
    img.onerror = reject;
    img.src = url;
  });
}

export function BeforeAfterOutput({
  inputURL,
  outputURL,
}: {
  inputURL: string;
  outputURL: string;
}) {
  const [aspectRatio, setAspectRatio] = useState<number | null>(null);

  useEffect(() => {
    getImageAspectRatio(inputURL)
      .then((aspectRatio) => {
        setAspectRatio(aspectRatio);
      })
      .catch(() => {
        setAspectRatio(1);
      });
  }, [inputURL]);

  return (
    <div className="flex items-center justify-center h-full">
      <AnimatePresence>
        {aspectRatio && (
          <motion.div
            style={{
              aspectRatio,
              height: "100%",
            }}
            className="group"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{
              opacity: 0,
            }}
          >
            <ReactCompareSlider
              itemOne={
                <div className="h-full w-full relative">
                  <div className="absolute top-0 left-4 bottom-0 pointer-events-none flex items-center justify-center transition-opacity opacity-100 group-hover:opacity-0">
                    <span className="bg-black/70 text-white px-2 py-1 text-r8-sm font-semibold">
                      Before
                    </span>
                  </div>
                  <ReactCompareSliderImage
                    className="!object-contain"
                    src={inputURL}
                    alt="Input image"
                  />
                </div>
              }
              itemTwo={
                <div className="h-full w-full">
                  <div className="absolute top-0 right-4 bottom-0 pointer-events-none flex items-center justify-center transition-opacity opacity-100 group-hover:opacity-0">
                    <span className="bg-black/70 text-white px-2 py-1 text-r8-sm font-semibold">
                      After
                    </span>
                  </div>
                  <ReactCompareSliderImage
                    className="!object-contain"
                    src={outputURL}
                    alt="Output image"
                  />
                </div>
              }
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
