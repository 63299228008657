import type { APIPrediction } from "@/types";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import type { Model, Prediction } from "replicate";
import { PredictionOutput } from "./prediction-output";
import { useActiveModel } from "@/store";
import { AnimatePresence, motion } from "framer-motion";
import { PredictionLoadingState } from "./prediction-loading-state";
import { PredictionErrorState } from "./prediction-error-state";
import { PredictionDetail } from "./prediction-detail";

function InitialOutput() {
  const activeModel = useActiveModel();
  const outputToShow = activeModel.default_example?.output;

  return (
    <PredictionOutput
      id={activeModel.default_example?.id}
      output={outputToShow}
      input={activeModel.default_example?.input}
      key={`${activeModel.owner}/${activeModel.name}`}
    />
  );
}

function PostSubmitOutput({
  mutation,
  query,
}: Omit<PlaygroundFormOutputProps, "submitState">) {
  return (
    <AnimatePresence mode="wait">
      {mutation.status === "error" && (
        <motion.div
          key="error"
          className="h-full flex flex-col flex-1 bg-brand-red/10"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <PredictionErrorState error={mutation.error} />
        </motion.div>
      )}
      {(mutation.status === "success" || mutation.status === "pending") && (
        <motion.div
          className="h-full flex flex-col flex-1"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {mutation.data && mutation.status === "success" && (
            <PredictionDetail query={query} />
          )}
          {mutation.status === "pending" && <PredictionLoadingState />}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

interface PlaygroundFormOutputProps {
  submitState: "pre" | "post";
  query: UseQueryResult<APIPrediction, Error>;
  mutation: UseMutationResult<
    APIPrediction,
    Error,
    {
      model: Model;
      prompt: string;
      name: string;
      defaultExample: Prediction;
    },
    unknown
  >;
}

export function PlaygroundFormOutput({
  submitState,
  ...rest
}: PlaygroundFormOutputProps) {
  return submitState === "pre" ? (
    <InitialOutput />
  ) : (
    <PostSubmitOutput {...rest} />
  );
}
