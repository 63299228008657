import { useCopyToClipboard } from "@/hooks";
import { CodeCTAs } from "./code-ctas";
import { InteractiveCodeSnippet } from "./interactive-code-snippet";
import { makePythonRunSnippet } from "@/code-snippets";
import { getModelDisplayInput } from "@/util";
import { isOfficialModel, isStreamingModel } from "@/data";
import type { PlaygroundModel } from "@/types";

export function PythonCodeForm({
  name,
  model,
  prompt,
}: {
  name: string;
  model: PlaygroundModel;
  prompt: string;
}) {
  const [copiedValue, copy] = useCopyToClipboard();
  const modelFullName = `${model.owner}/${model.name}`;

  const isOfficial = isOfficialModel(modelFullName);
  const isStreaming = isStreamingModel(modelFullName);

  const code = makePythonRunSnippet({
    model,
    input: getModelDisplayInput({ model, name, prompt }),
    isOfficial,
    isStreaming,
  });

  const handleCopy = () => {
    copy(code);
  };

  return (
    <div className="group">
      <CodeCTAs onCopy={handleCopy} copiedValue={copiedValue} />
      <InteractiveCodeSnippet
        markers={[
          {
            line: 1,
            className: "opacity-50 group-hover:opacity-100 transition-opacity",
          },
        ]}
        language="python"
        code={code}
      />
    </div>
  );
}
