export function PredictionProgressBar({ value }: { value: number | null }) {
  return (
    <div className="relative">
      <span className="sr-only" id="prediction-progress-label">
        Prediction progress
      </span>
      <div
        role="progressbar"
        aria-labelledby="prediction-progress-label"
        aria-valuemin={0}
        aria-valuenow={value ?? 0}
        aria-valuemax={100}
      >
        <div className="w-full bg-white border border-r8-gray-12 h-3 p-[2px]">
          <div
            className="bg-black h-full transition-all"
            style={{
              // Rather than show an empty progress bar, we'll show a small
              // amount of progress so that the user knows something is happening
              width: `${value ?? 5}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
