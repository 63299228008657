import * as Sentry from "@sentry/astro";
import {
  PredictionError,
  RateLimitError,
  createPrediction,
  fetchPrediction,
} from "@/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { APIPrediction } from "@/types";
import {
  API_BASE_URL,
  PREDICTION_REFETCH_INTERVAL,
  TERMINAL_PREDICTION_STATUSES,
} from "@/constants";
import { configResponsive, useResponsive } from "ahooks";
import type { Prediction } from "replicate";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

export const queryKeys = {
  predictions: {
    uuid: (uuid?: string) =>
      ["predictions", uuid] as ["predictions", string | undefined],
    filterKey: ["predictions"] as const,
  },
};

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      setTimeout(() => setCopiedText(null), 2000);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export function useLazyImage(src: string) {
  const [loaded, setLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
      setImageSrc(src);
    };

    // ignoring errors for now
    img.onerror = () => {};
  }, [src]);

  return { loaded, imageSrc };
}

export function useCreatePrediction() {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: createPrediction,
    onSuccess: (prediction) => {
      client.setQueryData(
        queryKeys.predictions.uuid(prediction.id),
        prediction,
      );
    },
    onError: (err) => {
      if (import.meta.env.DEV) return;
      if (err instanceof RateLimitError) return;

      let extra = {};
      if (err instanceof PredictionError) {
        extra = {
          model: err.model,
          prompt: err.prompt,
        };
      }

      Sentry.captureException(err, {
        extra,
      });
    },
  });

  return mutation;
}

export function useGetPrediction({
  id,
  defaultExample,
}: { id?: string; defaultExample: Prediction }) {
  return useQuery({
    enabled: Boolean(id),
    queryFn: ({ signal }) => {
      // When the id is the same as the default example, we can go ahead
      // and return the default example immediately rather than fetching
      // it again from the API.
      if (id === defaultExample.id) {
        return Promise.resolve(defaultExample as APIPrediction);
      }
      return fetchPrediction({ id, signal });
    },
    queryKey: queryKeys.predictions.uuid(id),
    refetchOnWindowFocus(query) {
      const lastKnownStatus = query.state.data?.status;
      if (!lastKnownStatus) return false;
      return !TERMINAL_PREDICTION_STATUSES.includes(lastKnownStatus);
    },
    refetchInterval: (query) => {
      if (!query.state.data) return false;
      if (TERMINAL_PREDICTION_STATUSES.includes(query.state.data.status)) {
        return false;
      }
      return PREDICTION_REFETCH_INTERVAL;
    },
  });
}

/**
 * Returns a mutation object when called (via the .mutate() method)
 * will cancel any in-flight predictions. Useful for cleaning up
 * when the component unmounts or the user navigates away from the
 * web page.
 * TODO: This probably doesn't need to use the `useMutation` hook.
 */
export function useCancelPendingPredictions() {
  // We leverage the react-query cache to find all pending predictions
  // from it we can retrieve the prediction queries and their associated
  // ids. From here we use the web Beacon API to send a "fire-and-forget"
  // request to the backend to attempt to cancel pending predictions.
  const client = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const predictions = client
        .getQueriesData({
          queryKey: queryKeys.predictions.filterKey,
          predicate: ({ queryKey }) => !!queryKey[1],
        })
        .map(([_, p]) => p as APIPrediction);

      const active = predictions.filter(
        (p) => p && !TERMINAL_PREDICTION_STATUSES.includes(p.status),
      );
      if (active.length > 0) {
        navigator.sendBeacon(
          `${API_BASE_URL}/api/cancel`,
          JSON.stringify(active.map((x) => x.id)),
        );
      }

      // Clean up the query cache to remove all predictions.
      client.removeQueries({ queryKey: ["prediction"], exact: false });
    },
  });
}

configResponsive({
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
});

export { useResponsive };
