import { LogoGlyph } from "../logo-glyph";
import { RateLimitError } from "@/api";

export function PredictionErrorState({
  error,
}: {
  error: Error;
}) {
  const message =
    error instanceof RateLimitError
      ? "The service is extremely busy and we were unable to run this model. Please try again in a moment."
      : "Uh oh, something went wrong. Please try again.";

  return (
    <div className="h-full flex items-center justify-center flex-col">
      <div className="w-8 h-8 text-brand-red">
        <LogoGlyph />
      </div>
      <div className="text-center mt-3 space-y-3">
        <p className="text-sm text-brand-red px-8">{message}</p>
      </div>
    </div>
  );
}
