import { parsePredictionProgress } from "@/util";
import { LogoGlyph } from "../logo-glyph";
import { PredictionProgressBar } from "./prediction-progress-bar";

export function PredictionLoadingState({ logs }: { logs?: string | null }) {
  const predictionProgress = parsePredictionProgress(logs);

  return (
    <div className="h-full flex-col flex items-center justify-center relative text-black bg-white">
      <div className="text-center z-10">
        <div className="w-8 h-8 animate-pulse mx-auto">
          <LogoGlyph />
        </div>
        <div className="text-center mt-3">
          <p className="text-sm">Running...</p>
          <div className="w-52 mt-3">
            <PredictionProgressBar
              value={
                predictionProgress ? predictionProgress.percentage * 100 : null
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
