// @ts-ignore
import Lowlight from "react-lowlight";

// @ts-ignore
import javascript from "highlight.js/lib/languages/javascript";
// @ts-ignore
import bash from "highlight.js/lib/languages/bash";
// @ts-ignore
import python from "highlight.js/lib/languages/python";

// Then register them with lowlight
Lowlight.registerLanguage("javascript", javascript);
Lowlight.registerLanguage("bash", bash);
Lowlight.registerLanguage("python", python);

export { Lowlight };
